
import React from "react";
import { useTranslation } from "react-i18next";

const SocialTwo = () => {
  const { i18n } = useTranslation("global");

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <button className="first" onClick={() => changeLanguage("es")}>
        Español
      </button>
      <button className="second" onClick={() => changeLanguage("en")}>
        English
      </button>

      <style jsx>{`
        .first,
        .second {
          background: transparent;
          color: white;
          padding: 0.1px 0.1px;
          margin: 0.1px;
          cursor: pointer;
          outline: none;
        }

        .first:hover,
        .second:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      `}</style>
    </>
  );
};

export default SocialTwo;
